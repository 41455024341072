@import '../node_modules/voxloud-ui-toolkit/dist/scss/components/variables.scss';
@import "../node_modules/voxloud-ui-toolkit/dist/css/style.min.css";

body {
  font-family: $vox-gtEestiPro-font;
}

.pac-container {
  & .pac-item {
    font-family: $vox-gtEestiPro-font !important;
  }
}

// Global rule to override ladda default hover color
.ladda-button[disabled]:hover {
  background-color: #e5e9f0;
}

// Fix to avoid that the tooltip goes over the modal
.modal-window {
  z-index: 9999;
}

.modal-content {
  -webkit-overflow-scrolling: touch;
}

// Set z-index to 9999
.huge-z-index {
  z-index: 9999 !important;
}

// hide google captcha badge
.grecaptcha-badge {
  opacity: 0;
}

// TODO: below fix should be moved to ng-voxloud and removed from here
.vox-select-prefix {
  .vox-input-group-field_toggle > span {
    font-size: 20px!important;
    font-weight: 300;
  }
  .vox-input-group-field .dropdown-menu .dropdown-item .dropdown-item_code {
    min-width: 40px;
  }
}

onb-coverage-success-scenario{
  .form-container{
    .vox-dropdown-input{
      .dropdown-toggle{
        min-height: 56px;
      }
    }
    .vox-input-group_invalid{
      vox-phone-text-field{
        .flag-container,
        .phone-input,
        .phone-prefix,
        .icon-container{
          border-color: $bright-crimson;
        }
      }
    }
  }
}

onb-coverage-fallback-form{
  .typeahead-window{
    width: 100%;
  }
}
// uniform font size
vox-select-prefix span{
  font-size: 20px !important;
}

/*
* There is a problem with modal backdrop z-index after updating ng-bootstrap.
* We found solution there - https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
*/
ngb-modal-backdrop {
  z-index: 1050 !important;
}
